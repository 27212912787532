import React from 'react';
import Layout from '../components/layout';
import CtaDeck from '../components/common/cta-deck';

const headMeta = {
  title: 'Attorney Advertising | Cookie Policy | Disclaimers | Pandev Law',
  description:
    'Learn more about Pandev Law&#39;s Attorney Advertising, Cookie, and Disclaimer Policy.',
  keywords: '',
};

const schema = `{}`;

function AdvertisingDisclaimers({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <div className="section pt-5 pb-5 pt-md-10 pb-md-10">
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 col-lg-8">
              <h1 className="text-mega mb-8">
                Attorney Advertising, Disclaimers &amp; Cookie Policy
              </h1>
              <h2 className="text-display mb-6">Attorney Advertising Notice</h2>
              <p className="text-body mb-6">
                The purpose of this Pandev Law, LLC (“firm”, “I”, “we” or “us”) website is
                to provide information about the qualifications and experience of the firm
                and its attorney.
              </p>
              <p className="text-body mb-6">
                <strong>
                  THE INFORMATION PROVIDED ON THIS WEBSITE DOES NOT, AND IS NOT INTENDED
                  TO, CONSTITUTE LEGAL ADVICE ON ANY SUBJECT MATTER.
                </strong>
              </p>
              <p className="text-body">
                Blog articles may be considered attorney advertisement. Any article or
                commentary posted on this website does not constitute legal advice nor
                does it establish an attorney-client relationship. Any prior results
                described on this website or elsewhere do not guarantee a similar outcome.
                <br />
                <br />
                If you have questions regarding attorney advertising rules or this notice,
                please contact us at{' '}
                <a href="mailto:admin@pandevlaw.com" className="link-primary">
                  admin@pandevlaw.com
                </a>
                .
              </p>
              <h2 className="text-display mt-8 mb-6">Notice / Disclaimer</h2>
              <p className="text-body mb-6">
                Pandev Law, LLC’s attorney is licensed to practice law in the US states of
                New York and South Carolina. You understand Pandev Law, LLC shall not
                express&nbsp;opinions as to the laws of any other state or jurisdiction
                other than the states listed above&nbsp;and the federal laws of the United
                States of America.
                <br />
                <br />
                No user of this website, or any content thereof, should act or refrain
                from acting based on any&nbsp;information contained on this website
                without first consulting appropriate legal or other
                professional&nbsp;counsel, licensed in the relevant&nbsp;jurisdiction, on
                the particular facts and circumstances at issue. PANDEV LAW, LLC EXPRESSLY
                DISCLAIMS ANY AND ALL LIABILITY WITH RESPECT TO ACTIONS TAKEN, OR NOT
                TAKEN, BASED ON ANY CONTENT OF THIS WEBSITE, SOCIAL OR OTHER MEDIA
                POSTINGS, OR PANDEV LAW, LLC MEDIA APPERANCES OF ANY KIND.
                <br />
                <br />
                While the information on the website concerns legal issues, it is not
                legal advice. In addition, use of&nbsp;the website is not intended to
                constitute, and does not constitute, a solicitation for the formation of
                an&nbsp;attorney-client relationship. Neither receipt of information
                presented in person, nor any mail, fax, email or&nbsp;electronic
                communication sent through this website will create an attorney-client
                relationship. Nor will&nbsp;any such email or communication will be
                treated as confidential.
              </p>
              <p className="text-body mb-6">
                <strong>
                  NO ATTORNEY-CLIENT RELATIONSHIP IS CREATED THROUGH YOUR USE OF&nbsp;THIS
                  WEBSITE, INCLUDING THE BLOG.
                </strong>
              </p>
              <p className="text-body">
                Before Pandev Law, LLC can&nbsp;represent you, or render any legal advice,
                you and Pandev Law, LLC must mutually agree to such representation by
                entering&nbsp;into a written contract, also known as an engagement letter,
                representation agreement, or retainer agreement. Such
                agreement&nbsp;explains the cost of representation and the scope of any
                legal work that Pandev Law, LLC may perform for you.
              </p>
              <p className="text-display mt-8 mb-6">
                <strong>Privacy & Cookie Policy</strong>
              </p>
              <p className="text-body">
                Pandev Law, LLC is committed to protecting the privacy of our online
                visitors. We do not collect personally identifiable information about
                individuals except when it is knowingly provided by such individuals. We
                hope that you will read this privacy statement carefully so you will
                clearly understand both Pandev Law, LLC’s commitment to you and your
                privacy and our method of collecting and using information.
                <br />
                <br />
                Contact information on this website is not provided as a means for
                prospective clients to submit&nbsp;information to us. Pandev Law, LLC only
                collects personally identifiable information about you through specific
                requests directly addressed to you, and only if you choose to provide it
                to us. Pandev Law, LLC’s website does not, and is not intended to, request
                personally identifiable information about you. This personally
                identifiable information may include, for&nbsp;example, your name,
                address, telephone number, country of residence, and email address, as
                well as specific information&nbsp;relevant to the information you request.
                We request that you do not disclose any private, time-sensitive,&nbsp;or
                confidential information to us via email, telephone, fax or through this
                website. Pandev Law, LLC&nbsp;cannot guarantee the security of information
                transmitted to us over the internet. The communication&nbsp;of any
                electronic or telephonic inquiry does not, by itself, create an
                attorney-client relationship or&nbsp;contractually obligate Pandev Law,
                LLC to represent you, regardless of the content of such inquiry. Simply
                put, contacting Pandev Law, LLC does not mean that we are acting as your
                attorney or that we have agreed&nbsp;to represent you, or advise you, in
                any capacity. Do not send us any confidential or
                proprietary&nbsp;information unless, or until, a formal attorney-client
                relationship has been established.
                <br />
                <br />
                Pandev Law, LLC may request personally identifiable information from you,
                such as your name, address, telephone number, country of residence, and
                email address, as well as specific information&nbsp;relevant to the
                information you request. Your response to these inquiries is strictly
                voluntary. Pandev Law, LLC may use this information to customize your
                experience on our website. In addition, Pandev Law, LLC may use this
                information for other business purposes, such as to alert you about
                services.
                <br />
                <br />
                Pandev Law, LLC collects domain information as part of its analysis of the
                use of this website. This data enables us to become more familiar with the
                visitors who come to our website, how often they visit, and what parts of
                the website they visit most often. Pandev Law, LLC uses this information
                to improve its web-based marketing. This information is collected
                automatically and requires no action on your part.
                <br />
                <br />A “cookie” is a small data file that certain websites write to your
                hard drive for identification purposes when you visit them. A cookie file
                can contain information such as a user ID that the website uses to track
                the pages you have visited. However, the only personal information a
                cookie can contain is information you supply yourself. A cookie cannot
                read data off your hard disk or read cookie files created by other
                websites. Some pages on this website may use cookies. These cookies are
                used for website registration and customization the next time you visit
                us.
                <br />
                <br />
                If you prefer not to receive cookies on our website, you can set your
                browser to warn you before accepting cookies and refuse the cookie when
                your browser alerts you to its presence. You can also refuse all cookies
                by turning them off in your browser. By not accepting cookies, some pages
                may not fully function and you may not be able to access certain
                information on this website. Pandev Law, LLC may engage reputable third
                party vendors in order to help us manage our website and allow us to
                better service our visitors. Pandev Law, LLC requires its vendors to
                maintain the confidentiality and security of Pandev Law, LLC’s information
                to which they are provided access and restricts the vendors from using the
                information in a way not authorized by Pandev Law, LLC. This website may
                link to websites maintained by third-parties and outside organizations,
                including our&nbsp;clients. Pandev Law, LLC does not necessarily endorse,
                and is not responsible for, any third-party content that&nbsp;may be
                accessed through this website. We are not responsible for the content or
                policies maintained by&nbsp;these websites. Please familiarize yourself
                with any privacy policy and terms of use of any third-party&nbsp;website
                you visit. Pandev Law, LLC expressly disclaims any and all liability in
                connection with any third party&nbsp;content that may be accessed through
                this website.
                <br />
                <br />
                Pandev Law, LLC reserved the right to change, modify, or update this
                statement at any time without notice.
                <br />
                <br />
                &copy; 2017 – 2019 Pandev Law, LLC. The reproduction or retransmission of
                the contents of this website is prohibited without the prior written
                consent of Pandev Law, LLC.
              </p>
            </div>
          </div>
        </div>
      </div>
      <CtaDeck />
    </Layout>
  );
}

export default AdvertisingDisclaimers;
